import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProfilePic from "../images/profile.jpg"
import styles from "./index.module.scss"
import resume from "../files/2022_resume.pdf"
import { Link } from "gatsby"

const IndexPage = () => (
  <Layout>
    <SEO
      title="James Ku"
      description="I post about web development, software engineering, programming, etc."
      keywords={[`web dev`, `programming`, `Jia Jiun Ku`, `James`, `tech`]}
    />
    <div className={styles.bioContainer}>
      <div className={styles.bio}>
        <h3>Fullstack Engineer</h3>
        <h1>
          Coding to solve business problems or googling to solve code
          problems...
        </h1>
        <p>
          Fullstack developer with over 8 years of programming history since
          high school. Keen about learning technologies and realizing ideas with
          code.
        </p>
        <Link to="/projects" className="btn-action">
          View my projects
        </Link>
        <a
          className={"btn-secondary " + styles.resumeDownloadButton}
          href={resume}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download my resume
        </a>
      </div>
      <div className={styles.profile}>
        <div className={styles.profilePicPop}>
          <img src={ProfilePic} alt="My profile" />
        </div>
        <div className={styles.links}>
          <a
            href="https://github.com/imjamesku"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-github fa-2x" size></i>
          </a>
          <a
            href="https://www.linkedin.com/in/jiajiunku/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin fa-2x" size></i>
          </a>
          {/* <a href="https://www.instagram.com/jameskutw/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram fa-2x" size></i>
        </a> */}
          <a
            href="mailto:jameskubusiness@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fas fa-envelope-square fa-2x"></i>{" "}
            jameskubusiness@gmail.com
          </a>
        </div>
      </div>
    </div>
    <div className={styles.experience}>
      <h1>Experience</h1>
      <div className={styles.experienceBox}>
        <div>
          <h3>Fullstack Engineer</h3>
          <p>KADOKAWA Taiwan, April 2021 - Current</p>
          <p style={{ marginBottom: "0" }}>
            <strong>KadoKado</strong> (Digital publishing platform)
          </p>
          <ul style={{ marginTop: "0" }}>
            <li>
              Built frontend components & backend APIs using{" "}
              <strong>React</strong>,<strong>GraphQL</strong>,{" "}
              <strong>Node.js</strong>, <strong>PostgreSQL</strong>, etc.
            </li>
            <li>
              Designed & Implemented the <strong>transaction system</strong>,
              enabling buying & renting chapters of books using points, as well
              as redeeming vouchers
            </li>
            <li>
              Introduced <strong>automated unit testing</strong> to backend
              service & repository layers using{" "}
              <strong>jest & github actions</strong>
            </li>
            <li>
              Implemented a backstage with <strong>React & Antd</strong> for
              editing content & generating/managing voucher codes
            </li>
            <li>
              Implemented frontend <strong>authentication</strong> components
              following <strong>OIDC</strong>
            </li>
            <li>
              Reduced cost significantly by uploading static files to{" "}
              <strong>Google Cloud Storage</strong> in <strong>CI</strong> flow
              for serving
            </li>
          </ul>
          <p>
            <strong>Integration of Shopify & ERP</strong>
          </p>
          <p>
            <ul style={{ marginTop: 10 }}>
              <li>
                Coded <strong>CronJobs</strong> to automatically crawl quantity
                of products from the supplier’s website with{" "}
                <strong>regex</strong>
              </li>
              <li>
                Automated the process of issuing invoices & updating shipment
                status by using <strong>webhooks</strong> and{" "}
                <strong>task queues</strong>
              </li>
              <li>
                Implemented an <strong>auto-login</strong> script for shopify
                users to login from KADOKAWA’s account center through{" "}
                <strong>OIDC</strong>
              </li>
            </ul>
            <p>
              <strong>KTW Account </strong>
              (account center for all KADOKAWA’s services)
              <ul>
                <li>
                  Improved readability & maintainability of KADOKAWA’s account
                  center by introducing <strong>Typescript</strong>
                </li>
                <li>
                  Coded scripts using TypeScript to migreate member & bonus data
                  from legacy Ecommerce site
                </li>
              </ul>
            </p>
            <p>Others</p>
            <ul>
              <li>
                Deployed and managed microservices with{" "}
                <strong>serverless architecture</strong>
                using <strong>Google Cloud Platform</strong>
              </li>
              <li>
                Created/Updated <strong>CI/CD</strong> flows for some projects
                using <strong>Docker</strong> & <strong>GitHub Actions</strong>
              </li>
              <li>
                Deployed legacy websites on <strong>cloud VMs</strong> using{" "}
                <strong>Apache</strong> and updated/maintained them
              </li>
            </ul>
          </p>
        </div>
      </div>
      <div className={styles.experienceBox}>
        <div>
          <h3>Masters Student</h3>
          <p>University of Virginia, September 2019 - December 2020</p>
          <p style={{ marginTop: 10 }}>
            <ul>
              <li>
                First semester:
                <ul className={styles.courseList}>
                  <li className={styles.courseListItem}>NLP</li>
                  <li className={styles.courseListItem}>Machine Learning</li>
                  <li className={styles.courseListItem}>Geometry of Data</li>
                  <li className={styles.courseListItem}>
                    Design and Analysis of Algorithms
                  </li>
                </ul>
              </li>
              <li>
                Second semester:
                <ul className={styles.courseList}>
                  <li className={styles.courseListItem}>Cyber forensics</li>
                  <li className={styles.courseListItem}>Software Analysis</li>
                  <li className={styles.courseListItem}>Computer Networks</li>
                  <li className={styles.courseListItem}>
                    Advanced Operating Systems
                  </li>
                </ul>
              </li>
              <li>
                Final semester:
                <ul className={styles.courseList}>
                  <li className={styles.courseListItem}>Vision and Language</li>
                  <li className={styles.courseListItem}>HCI</li>
                </ul>
              </li>
            </ul>
          </p>
        </div>
      </div>
      <div className={styles.experienceBox}>
        <div>
          <h3>Backend Developer</h3>
          <p>Aurora Tech, November 2018 - July 2019</p>
          <p>
            <ul style={{ marginTop: 10 }}>
              <li>
                Developed the backend API for a lottery game web app using{" "}
                <b>PHP</b>, <b>Redis</b>, <b>RabbitMQ</b>, and <b>MySQL</b>
              </li>
              <li>
                Implemented logic for computing bet results and updating
                player's pockets
              </li>
              <li>
                Improved the performance of some methods by over <b>200%</b> by
                merging <b>Redis</b> and <b>SQL</b> requests
              </li>
              <li>Reduced redundant code by refactoring methods and classes</li>
              <li>
                Designed and implemented how to store and automatically update
                lottery statistics each term
              </li>
            </ul>
          </p>
          {/* <Button as={Link} to="/projects">Details</Button> */}
        </div>
      </div>
      <div className={styles.experienceBox}>
        <div>
          <h3>Undergraduate Student</h3>
          <p>National Tsing Hua University, September 2014 - June 2018</p>
          <p>
            <ul style={{ marginTop: 10 }}>
              <li>
                Assisted in the derivation of an algorithm for constructing
                neighbor-dependent node-weighted multicast trees
              </li>
              <li>
                Designed object-oriented program architecture and implemented
                simulation program using C++
              </li>
              <li>
                Automated test data calculation with Python and visualized the
                test results with Matlab
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
